import React, { createContext, useState, useContext } from 'react';

export const NftContext = createContext();

export function useNftContext() {
  return useContext(NftContext);
}

export const NftProvider = ({ children }) => {
  const [userNfts, setUserNfts] = useState([]);
  const [loading, setLoading] = useState(true);

  return (
    <NftContext.Provider value={{ userNfts, setUserNfts, loading, setLoading }}>{children}</NftContext.Provider>
  );
};
