import './tokenbalances.css';
import React, { useState, useEffect } from 'react';

const TokenTable = ({ tokenData }) => {
  const [tokenMetadata, setTokenMetadata] = useState({});
  const { Network, Alchemy } = require("alchemy-sdk");

  const settings = {
    apiKey: process.env.REACT_APP_ALCHEMY_KEY, // Replace with your Alchemy API Key.
    network: Network.ETH_MAINNET, // Replace with your network.
  };

  const alchemy = new Alchemy(settings);

  useEffect(() => {
    const fetchTokenMetadata = async (contractAddress) => {
      try {
        const metadata = await alchemy.core.getTokenMetadata(contractAddress);
        return metadata;
      } catch (error) {
        console.error(`Failed to fetch token metadata for ${contractAddress}`, error);
        return null;
      }
    };

    const updateTokenMetadata = async () => {
      const newTokenMetadata = {};

      // Iterate over the tokenData array and fetch metadata for each contract address
      await Promise.all(
        tokenData.map(async (token) => {
          const metadata = await fetchTokenMetadata(token.contractAddress);
          if (metadata) {
            newTokenMetadata[token.contractAddress] = metadata;
          }
        })
      );

      setTokenMetadata(newTokenMetadata);
    };

    updateTokenMetadata();
  }, [tokenData]);

  return (
    <table>
      {/* Table header */}
      <thead>
        <tr>
          <th>ERC20</th>
          <th>Balance</th>
        </tr>
      </thead>
      {/* Table body */}
      <tbody>
        {tokenData
          .filter((token) => parseInt(token.tokenBalance, 16) > 0)
          .sort((a, b) => parseInt(b.tokenBalance, 16) - parseInt(a.tokenBalance, 16))
          .map((token) => {
            const tokenInfo = tokenMetadata[token.contractAddress] || {};
            return (
              <tr key={token.contractAddress}>
                <td>{tokenInfo.name || token.contractAddress}</td>
                <td>{(parseInt(token.tokenBalance, 16) / 10 ** 18).toFixed(2)}</td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

export default TokenTable;

