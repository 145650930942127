import React from "react";
import logo from "./images/foundnone-hoodie-logo-02.png";
import {
  useAccount,
  useConnect,
  useEnsName,
  useEnsAvatar,
  configureChains,
} from "wagmi";
import { InjectedConnector } from "@wagmi/core/connectors/injected";
import { useWeb3Modal } from "@web3modal/react";
import { ethers } from "ethers";
import { mainnet } from "wagmi/chains";
import { alchemyProvider } from "wagmi/providers/alchemy";
import TokenTable from "./utils/tokenbalances";
import NftTable from "./utils/nftbalances";
import { NftProvider } from "./utils/NftContext";
import NftGallery from "./utils/carousel";

function HomePage() {
  const [balance, setBalance] = React.useState("");
  const [blockNumber, setBlockNumber] = React.useState("");
  const [gasPrice, setGasPrice] = React.useState("");
  const [txCount, setTxCount] = React.useState("");
  const [tokenBalances, setTokenBalances] = React.useState([]);
  const { Network, Alchemy } = require("alchemy-sdk");
  const [network, setNetwork] = React.useState(null);
  const [activeTab, setActiveTab] = React.useState("tokens");

  const { chains, provider } = configureChains(
    [mainnet],
    [alchemyProvider({ apiKey: process.env.REACT_APP_ALCHEMY_KEY })]
  );

  const settings = {
    apiKey: process.env.REACT_APP_ALCHEMY_KEY, // Replace with your Alchemy API Key.
    network: Network.ETH_MAINNET, // Replace with your network.
  };

  const alchemy = new Alchemy(settings);

  const { open } = useWeb3Modal();
  const { isConnected, address } = useAccount();
  const { connect } = useConnect({
    connector: new InjectedConnector(),
  });
  const ensAvatarQuery = useEnsAvatar(address ? { address } : undefined);
  const ensNameQuery = useEnsName(address ? { address } : undefined);

  const { data: ensAvatar } = ensAvatarQuery;
  const { data: ensName } = ensNameQuery;

  function handleTabClick(tab) {
    setActiveTab(tab);
  }

  async function switchToMainnet() {
    if (window.ethereum) {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x1" }],
        });
      } catch (error) {
        console.error(error);
      }
    } else {
    }
  }

  async function getNetwork() {
    if (window.ethereum) {
      try {
        const chainId = await window.ethereum.request({
          method: "eth_chainId",
        });
        setNetwork({ chainId: parseInt(chainId, 16) });
      } catch (err) {
        console.error(err);
      }
    }
  }

  React.useEffect(() => {
    getNetwork();
  }, []);

  React.useEffect(() => {
    if (window.ethereum) {
      const handleChainChanged = async () => {
        await getNetwork();
      };

      window.ethereum.on("chainChanged", handleChainChanged);

      return () => {
        window.ethereum.removeListener("chainChanged", handleChainChanged);
      };
    }
  }, []);

  function shortenAddress(address, digits = 4) {
    if (!address || address.length < 11) {
      return address;
    }

    const start = address.slice(0, digits);
    const end = address.slice(-digits);
    return `${start}...${end}`;
  }

  async function getTokenBalances() {
    if (provider && chains);
    try {
      const tokenData = await alchemy.core.getTokenBalances(address);
      // Update the tokenBalances state with the parsed data
      setTokenBalances(tokenData.tokenBalances);
    } catch (e) {
      console.log(e);
    }
  }

  React.useEffect(() => {
    if (!network || network.chainId !== 1) return;

    // Call getTokenBalances() when the dependencies change
    getTokenBalances();
  }, [address, network]);

  async function fetchData() {
    if (!address || !provider) return;

    try {
      const [accountBalance, gasPrice, blockNumber, txCount] =
        await Promise.all([
          alchemy.core.getBalance(address),
          alchemy.core.getGasPrice(),
          alchemy.core.getBlockNumber(),
          alchemy.core.getTransactionCount(address),
        ]);

      setBalance(
        parseFloat(ethers.utils.formatEther(accountBalance)).toFixed(8)
      );
      setGasPrice(
        parseFloat(ethers.utils.formatUnits(gasPrice, "gwei")).toFixed(2)
      );
      setBlockNumber(blockNumber);
      setTxCount(txCount);
    } catch (err) {
      console.error(err);
    }
  }

  React.useEffect(() => {
    if (!network || network.chainId !== 1) return;

    fetchData();

    const interval = setInterval(() => {
      fetchData();
    }, 50000);
    return () => clearInterval(interval);
  }, [address, alchemy.core, network]);

  if (!isConnected) {
    return (
      <div className="container">
        <button className="connect-button" onClick={connect}>
          <img src={logo} alt="logo" className="logo" />
        </button>
      </div>
    );
  } else {
    return (
      <div>
        {network && network.chainId !== 1 ? (
          <div className="switch-networks">
            <p>Please connect to the Ethereum mainnet.</p>
            <button className="switch-button" onClick={switchToMainnet}>
              Switch Networks
            </button>
          </div>
        ) : (
          <>
            <div className="connected-container">
              <div className="content-wrapper">
                <div className="profile-wrapper">
                  <div className="stats-wrapper">
                    <div className="profile">
                      <button className="profile-button" onClick={open}>
                        <img
                          src={ensAvatar || logo}
                          alt="logo"
                          className={ensAvatar ? "connected-image" : "logo"}
                        />
                      </button>
                      <p>{ensName ? ensName : shortenAddress(address)}</p>
                      <p>Eth Balance: {balance}</p>
                      <p>Total Transactions: {txCount}</p>
                      <p>Current Block: {blockNumber}</p>
                      <p>Current Gas Price: {gasPrice}</p>
                    </div>
                  </div>
                  <div className="tabs">
                    <button
                      className={`tab-button ${
                        activeTab === "tokens" ? "active" : ""
                      }`}
                      onClick={() => handleTabClick("tokens")}
                    >
                      Tokens
                    </button>
                    <button
                      className={`tab-button ${
                        activeTab === "gallery" ? "active" : ""
                      }`}
                      onClick={() => handleTabClick("gallery")}
                    >
                      Gallery
                    </button>
                  </div>
                </div>
              </div>
              <NftProvider>
                <div className="table-wrapper">
                  {activeTab === "tokens" ? (
                    <>
                      <div className="token-table">
                        <TokenTable tokenData={tokenBalances} />
                      </div>
                      <div className="token-table">
                        <NftTable address={address} />
                      </div>
                    </>
                  ) : (
                    <NftGallery />
                  )}
                </div>
              </NftProvider>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default HomePage;
