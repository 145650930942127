import React, { useState, useEffect } from "react";
import { useNftContext } from "./NftContext";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const NftGallery = () => {
  const { userNfts, loading } = useNftContext();
  const [nftImages, setNftImages] = useState([]);

  useEffect(() => {
    refreshNftImages();
  }, [userNfts]);

  const refreshNftImages = () => {
    const newNftImages = [];
    if (userNfts) {
      for (let i = 0; i < 20; i++) {
        const index = Math.floor(Math.random() * userNfts.length);
        const image = userNfts[index]?.media[0]?.gateway;
        if (image) {
          newNftImages.push(image);
        }
      }
    }
    setNftImages(newNftImages);
  };

  if (loading) {
    return <p>loading</p>;
  }

  return (
    <>
      <Carousel
        className="carousel-container"
        showArrows={true}
        showThumbs={false}
        infiniteLoop={true}
        animationHandler={"fade"}
        dynamicHeight={true}
        stopOnHover={true}
        autoPlay={true}
        showIndicators={false}
      >
        {nftImages.map((image, index) => (
          <div key={index}>
            <img className="carousel-image" src={image} alt={`NFT ${index}`} />
          </div>
        ))}
      </Carousel>
      <button className="refresh-button" onClick={refreshNftImages}>
          Randomize
        </button>
    </>
  );
};

export default NftGallery;
