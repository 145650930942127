import "./nftbalances.css";
import React, { useState, useEffect, useContext } from "react";
import { NftContext } from "./NftContext";

const NftTable = ({ address }) => {
  const { setUserNfts, loading, setLoading } = useContext(NftContext);
  const [ownedNfts, setOwnedNfts] = useState([]);
  const { Network, Alchemy } = require("alchemy-sdk");

  const settings = {
    apiKey: process.env.REACT_APP_ALCHEMY_KEY,
    network: Network.ETH_MAINNET,
  };

  const alchemy = new Alchemy(settings);

  const fetchAllNftsForOwner = async (address, allNfts = [], pageKey = null) => {
    try {
      const response = await alchemy.nft.getNftsForOwner(address, { pageKey });
      const ownedNfts = response.ownedNfts;
      allNfts.push(...ownedNfts);

      if (response.pageKey) {
        return fetchAllNftsForOwner(address, allNfts, response.pageKey);
      } else {
        return allNfts;
      }
    } catch (error) {
      console.error(`Failed to fetch NFTs for ${address}`, error);
      return null;
    }
  };

  useEffect(() => {
    if (address) {
      setLoading(true);
      fetchAllNftsForOwner(address).then((allNfts) => {
        setOwnedNfts(allNfts);
        setUserNfts(allNfts);
        setLoading(false);
      });
    }
  }, [address, setUserNfts, setLoading]);

  const aggregateNfts = (nfts) => {
    const aggregatedNfts = {};

    nfts.forEach((nft) => {
      if (aggregatedNfts[nft.contract.address]) {
        aggregatedNfts[nft.contract.address].balance += parseInt(nft.balance, 10);
        aggregatedNfts[nft.contract.address].nfts.push(nft);
      } else {
        aggregatedNfts[nft.contract.address] = {
          ...nft,
          nfts: [nft],
          balance: parseInt(nft.balance, 10),
        };
      }
    });

    return Object.values(aggregatedNfts);
  };

  const aggregatedNfts = aggregateNfts(ownedNfts);

  return (
    <table>
      <thead>
        <tr>
          <th>NFT</th>
          <th>Balance</th>
        </tr>
      </thead>
      <tbody>
        {loading ? (
          <tr>
            <td colSpan="2">Loading...</td>
          </tr>
        ) : (
          aggregatedNfts
            .filter((nft) => nft.balance > 0)
            .sort((a, b) => b.balance - a.balance)
            .map((nft) => {
              const tokenInfo = nft.contract || {};
              return (
                <tr key={nft.contract.address}>
                  <td>
                    {tokenInfo?.name || tokenInfo?.openSea?.collectionName || nft.contract.address}
                  </td>
                  <td>{nft.balance}</td>
                </tr>
              );
            })
        )}
      </tbody>
    </table>
  );
};

export default NftTable;
